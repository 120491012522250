var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"file-upload-box__wrapper ma-0 pa-0",class:{ 'file-upload-box__wrapper--dragging': _vm.dragging },on:{"dragenter":function($event){_vm.dragging = true},"dragleave":function($event){_vm.dragging = false}}},[(_vm.hasUploadedFiles)?_c('div',{staticClass:"file-upload-box"},[_c('div',{staticClass:"uploaded-files"},[_c('span',{staticClass:"uploaded-files__title"},[_vm._v(_vm._s(_vm.$t('general.uploaded')))])]),_c('drop-list',{attrs:{"items":_vm.uploadedFiles},on:{"reorder":function($event){return $event.apply(_vm.uploadedFiles)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('drag',{key:item.id,staticStyle:{"width":"100%"},attrs:{"data":item}},[_c('FileUploadBoxUploadedItem',{attrs:{"upload-file":item,"is-main-document":_vm.isMainContract(item)},on:{"delete-uploaded-file":function($event){return _vm.deleteDocumentDialogMethod(true, item)},"upload-again":function($event){return _vm.$refs.filesInput.update(item, {
                active: true,
                error: '',
                progress: '0.00',
              })}}})],1)]}}],null,false,2653480164)}),_c('div',{staticClass:"uploaded-files__actions mt-6"},[(_vm.hasUploadedFiles)?_c('label',{staticClass:"file-upload-box__upload-next px-4 px-md-5",attrs:{"for":"document"}},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":"#7031B4","small":""}},[_vm._v("mdi-tray-arrow-up")]),_vm._v(" "+_vm._s(_vm.$t('upload.add_more'))+" ")],1):_vm._e(),(_vm.canContinue)?_c('v-btn',{staticClass:"file-upload-box__upload-label",attrs:{"color":"primary","loading":_vm.uploadingFiles},on:{"click":_vm.uploadFiles}},[_vm._v(" "+_vm._s(_vm.$t('contract.create_draft'))+" ")]):_vm._e()],1)],1):_c('div',{staticClass:"file-upload-box"},[_c('v-img',{staticClass:"mb-6",attrs:{"src":_vm.uploadDocumentSrc,"max-height":"122.96px","max-width":"137px","contain":""}}),_c('span',{staticClass:"file-upload-box__title mb-6"},[_vm._v(_vm._s(_vm.$t('upload.drop_zone')))]),_c('span',{staticClass:"file-upload-box__separator mb-6"},[_vm._v(_vm._s(_vm.$t('general.or')))]),_c('label',{staticClass:"file-upload-box__upload-label d-flex align-center justify-center",attrs:{"for":"document"}},[_c('v-icon',{attrs:{"color":"white","small":"","left":""}},[_vm._v("mdi-tray-arrow-up")]),_c('span',{staticClass:"upload-label__text"},[_vm._v(" "+_vm._s(_vm.$t('contract.choose_file'))+" ")])],1),_c('v-checkbox',{staticClass:"my-5 px-2",attrs:{"hide-details":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"options-menu__content--label"},[_vm._v(" "+_vm._s(_vm.$t('document.convert_to_pdf_a'))+" ")])]},proxy:true},{key:"append",fn:function(){return [_c('s-help',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(
                _vm.$t('document.convert_to_pdf_a.help', {
                  link: _vm.$t('links.confluence.convert_to_pdf_a'),
                })
              )}})]},proxy:true}])})]},proxy:true}]),model:{value:(_vm.convertToPdfA),callback:function ($$v) {_vm.convertToPdfA=$$v},expression:"convertToPdfA"}})],1),_c('file-upload',{ref:"filesInput",attrs:{"name":"document","drop":true,"post-action":_vm.uploadSettings.url,"headers":_vm.uploadSettings.headers,"multiple":_vm.multipleUpload},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile},model:{value:(_vm.uploadedFiles),callback:function ($$v) {_vm.uploadedFiles=$$v},expression:"uploadedFiles"}}),_c('SConfirmDialog',{attrs:{"message":`${_vm.$t('docs.delete.confirm')}`},on:{"confirmation-callback":function($event){return _vm.removeUploadedFile(_vm.deleteDocumentDialogObject.file)}},model:{value:(_vm.deleteDocumentDialogObject.show),callback:function ($$v) {_vm.$set(_vm.deleteDocumentDialogObject, "show", $$v)},expression:"deleteDocumentDialogObject.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }